.dt__cta {
    color: white;
    font-family: var(--font-family);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar);
    scroll-margin-top: 5em;
    box-shadow: 0 2px 4px 0 rgba(90, 116, 148, 0.4);
    
}

.dt__cta form{
    width: 50%;
    flex: 0.5;
    display: flex;
    flex-direction: column;
}
.dt__cta-content h3{
    color: var(--color-text);
    font-size: 20px;
    line-height: 30px;
    font-weight: 800;
}

.dt__cta select:invalid, .dt__cta form select :disabled { color: gray; }
.dt__cta-content p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    color: white;
}

.dt__cta-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}

.dt__cta-btn button {
    background: var(--color-text) ;
    color: white;
    border-radius: 40px;
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;

}
.dt__cta-btn button:hover{
    transform: scale(105%);
    transition: all 0.5s ease;
}

.dt__cta input, .dt__cta select, .dt__cta textarea {
    font-family: var(--font-family);
    outline: none;
    z-index: 1;
    position: relative;
    background: rgba(255,255,255,0.8);
    margin: 0.5rem 0;
    width: 100%;
    height: 50px;
    border: 0;
    color: #212121;
    font-size: 16px;
    font-weight: 600;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px 0 rgba(90, 116, 148, 0.4);
}






@media screen and (max-width: 650px) {
    .dt__cta {
        flex-direction: column;
    }

    .dt__cta h3{
        margin-bottom: 1rem;
        text-align: center;
    }
    .dt__cta-content p{
        text-align: center;
    }
    .dt__cta-btn {
        margin-left: 0;
    }
    .dt__cta form{
        width: 100%}

    .dt__cta input, .dt__cta select, .dt__cta textarea {

        padding: 0.5rem;

    }
}
@media screen and (max-width: 550px) {
    .dt__cta {
        margin: 4rem 1rem;
    }

    .dt__cta-content h3{
        font-size: 18px;
        line-height: 32px;
    }

    .dt__cta-btn button{
        font-size: 14px;
        line-height: 28px;
    }
}