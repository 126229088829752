.dt__features-container__feature{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    margin: 1rem;
}

.dt__features-container__feature-title {
    flex: 1;
    max-width: 180px;
    margin-right: 2rem;

}

.dt__features-container__feature-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: var(--color-blog);
}

.dt__features-container__feature-title div {
    width: 38px;
    height: 3px;
    background: var(--color-bar);
    box-shadow: 0 2px 2px rgba(0,0,0,0.25);
    margin-bottom: 0.25rem;
}

.dt__features-container__feature-text {
    flex:2;
    max-width: 100%;
    display: flex;
}

.dt__features-container__feature-text  p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-blog);
}

@media  screen and (max-width: 550px) {
    .dt__features-container__feature-title h1 {
        font-size: 14px;
        line-height: 22px;
    }
    .dt__features-container__feature-title p {
        font-size: 12px;
        line-height: 20px;
    }
    .dt__features-container__feature{
        margin: 1rem 0;
    }
}