.dt__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 6rem;
    background: var(--color-bg-nav);
    box-shadow: 0 1px 1px var(--shadow-2);
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 99;

    
}
.dt__navbar-offer_languages{
    display: flex;
    justify-content: end;
    
}


.dt__navbar ul li {
    list-style: none;
}

.dt__navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dt__navbar-links_logo{
    margin-right: 2rem;
}

.dt__navbar-links_logo img{
    width: 150px;
    height: 68px;
}

.dt__navbar-links_container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;

}

.dt__navbar-offer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}


.dt__navbar-links_container p,
.dt__navbar-offer p, 
.dt__navbar-menu_container p {
    color: #65676B;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}
.dt__navbar-offer .dt__navbar-dropdown .languages-btn, .dt__navbar-menu .dt__navbar-dropdown .languages-btn {
    background: #f5f5f5;
    margin-right: 1rem;
    cursor: pointer;
    outline: none;
    border: 0;
}

.dt__navbar-offer .dt__navbar-dropdown .languages-btn_secondary,
.dt__navbar-menu .dt__navbar-dropdown .languages-btn_secondary{
    color: #161840;
    background: transparent;
  
}


.dt__navbar-offer button,
.dt__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #FF2C65;
    border: none;
    border-radius: 8px;
    font-family: var(--font-family);
    font-weight: 500;
    cursor: pointer;
    border: 0;
    outline: none;

    
}
.dt__navbar-offer button:hover,
.dt__navbar-menu_container button:hover {
transform: scale(110%);
    transition: all 0.5s ease;}


.dt__navbar-menu{
    margin-left: 1rem;
    display: none;
    position: relative;
    
}
.dt__navbar-lang_menu{
    margin-right: 1rem;
    position: relative;
}
.dt__navbar-lang_menu svg{
    cursor: pointer;
}
.dt__navbar-menu svg {
    cursor: pointer;
}

.dt__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    background:var(--color-bg-nav) ;
    text-align: end;
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2) ;

}

.dt__navbar-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background:var(--color-bg-nav) ;
    text-align: center;
    padding: 1rem 0.5rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 100px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2) ;

}
.dt__navbar-dropdown button {
    background: transparent;
    outline: none;
    border: 0;
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 1rem;
    font-weight: bold;
    cursor: pointer;

}


.dt__navbar-menu_container p {
    margin: 1rem 0;
}

.dt__navbar-menu_container-offer {
    display: none;
}




@media screen and (max-width: 1050px){
    .dt__navbar-links_container{
        display: none;
    }
   
    .dt__navbar-menu{
        display: flex;
        z-index: 50;
    }
    .dt__navbar-links_logo{
        margin-right: 0;
    }
    .dt__navbar-links_logo img{
        width: 120px;
        height: 54px;
    }
    .dt__navbar{
        padding: 1rem 2rem;
    }
    .dt__navbar-menu_container-offer{
        display: none;
    }

}

@media screen and (max-width: 550px){
    .dt__navbar-offer{
        display: none;
    }
    .dt__navbar-menu_container-offer{
        display: block;
    }
    
}
