.dt__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-gray-20);
    font-family: var(--font-family);
}

.dt__footer-heading {
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
}

.dt__footer-heading h1 {
    font-family: var(--font-family);
    font-weight:800;
    font-size: 50px;
    text-align: center;
    letter-spacing: -0.04em;
}

.dt__footer-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    text-align: center;
    margin-bottom: 10rem;
    border: 2px solid var(--color-footer);
    cursor: pointer;
    border-radius: 4px;

}
.dt__footer-btn button {
    box-sizing: border-box;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-footer);
    font-weight: bold;
    word-spacing: 2px;
    background: transparent;
    border: 0;
    font-family: var(--font-family);
}

.dt__footer-phone_input{
    max-width: 14rem;
    margin-bottom: 1rem;
    color: var(--color-text);
}
.dt__footer-phone_input input {
    padding: 4px 8px;
    font-family: var(--font-family);
    background: var(--color-bg);
    border: 0;
    border-radius: 4px;
    outline: none;

}

.dt__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    text-align: left;
}

.dt__footer-links div {
    width: 250px;
    margin: 1rem;
}

.dt__footer-links_logo {
    display: flex;
    flex-direction: column;
}

.dt__footer-links_logo img {
    width: 150px;
    height: 68px;
    margin-bottom: 1rem;
}
.dt__footer-links_logo p{
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 20px;
}
.dt__footer-links_logo p span{
    font-weight: bold;
    color: #161840;
}

.dt__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

}

.dt__footer-links_div h4{
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 1rem;
    color: #161840;
}
.dt__footer-links_div p {
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 16px;
    margin: 0.5rem 0;
    cursor: pointer;
}

.dt__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;

}

.dt__footer-copyright p{
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 14px;

}

@media screen and (max-width:850px){
    .dt__footer-heading h1{
    font-size: 42px;
    line-height: 50px;
    }    
}
@media screen and (max-width:550px){
    .dt__footer-links{
        text-align: center;
        justify-content:center;
    }
    .dt__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .dt__footer-links div {
        margin: 1rem 0;
    }
    .dt__footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }
    .dt__footer-btn{

        margin-bottom: 4rem;
    }
    .dt__footer-links_logo {
        display: flex;
        justify-content: center;
        align-items: center;
    }

}