.dt__header {
    display: flex;
    scroll-margin-top: 2em;

}

.dt__header-content{
    flex:1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
}

.dt__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.dt__header-content p{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.dt__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
}

.dt__header-content__input form {
    display: flex;
}
.dt__header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    background: #fff;
    border-radius: 5px 0px 0px 5px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: rgba(28,43,51,.65);
    outline: none;
    border:1px solid #cbd2d9;
    padding: 0 1rem;


}

.dt__header-content__input ::placeholder{
    color: rgba(28,43,51,.65);
}

.dt__header-content__input button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    padding: 0.5rem 1rem;
    color: #fff;
    background: #FF2C65;
    font-size: 20px;
    line-height: 27px;
    border-radius: 0 5px 5px 0;
    font-family: var(--font-family);
    font-weight: 500;
    cursor: pointer;
    border: 1px solid #FF2C65;
    outline: none;
}

.dt__header-content__people{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.5rem;
}

.dt__header-content__people img{
    width: 181.79px;
    height: 38px;
}

.dt__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #65676B;
    text-align: center;

}

.dt__header-image{
    flex: 1 ;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dt__header-image img {
    width: 100%;
    height: auto;
}

@media screen and (max-width: 1050px) {
    .dt__header {
        flex-direction: column;
    }

    .dt__header-content {
        margin: 0 0 3rem;
    }

}


@media screen and (max-width: 650px) {
    .dt__header h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .dt__header p {
        font-size: 16px;
        line-height: 24px;
    }

    .dt__header-content__people{
        flex-direction: column;
    }
    .dt__header-content__people p{
        margin: 0;
    }

    .dt__header-content__input button, 
    .dt__header-content__input input
    {
        font-size: 16px;
        line-height: 24px;
    }


}

@media screen and (max-width: 490px){
    .dt__header {
        margin-top: 40px;
    }
    .dt__header h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .dt__header p {
        font-size: 14px;
        line-height: 24px;
    }
    .dt__header-content__input button, 
    .dt__header-content__input input
    {
        font-size: 12px;
        line-height: 16px;
    }
}