.dt__possibility {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    scroll-margin-top: 5em;


}

.dt__possibility-image {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 2rem;
}

.dt__possibility-image img {
    width: 100%;
    height: 100%;
}

.dt__possibility-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.dt__possibility-content h1 {
    font-family: var(--font-family);
    line-height: 45px;
    font-size: 30px;
    font-weight: 800;
    max-width: 510px;
    margin-bottom: 2rem;
}
.dt__possibility-content h4:first-child {
    font-family: var(--font-family);
    font-weight: 800;
    margin-bottom: 1rem;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: var(--color-blog);
}
.dt__possibility-content h4:last-child {
    font-family: var(--font-family);
    display: flex;
    font-weight: 800;
    margin-top: 2rem;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: var(--color-blog);
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.dt__possibility-content h4:last-child:hover{

    margin-left: 8px;
    transition: all 0.5s ease;
}
.dt__possibility-content h4:last-child img {
    width: 50px;
    margin-right: 0.5rem;
}

.dt__possibility-content p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-blog);
    text-indent: 2em;
}


@media screen and (max-width: 950px) {
    .dt__possibility {
        flex-direction: column;
    }

    .dt__possibility-image{
        margin: 1rem 0;
    }

    .dt__possibility-content{
        margin-top: 2rem;
    }
}